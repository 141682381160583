<template>
  <el-form
    v-if="formType.data"
    :model="formType.data"
    :rules="rules"
    :ref="formType.refName || 'ruleForm'"
    :label-width="allProps.labelWidth || '120px'"
    :inline="formType.value ? false : true"
    :class="formType.value ? '' : 'formFlex'"
  >
    <template v-for="item in allProps.items">
      <el-form-item
        :label="item.label"
        :prop="item.prop"
        :key="item.prop"
        v-if="item.type ? item.type.includes(formType.type) : false"
      >
        <el-select
          v-if="item.Ftype === 'select'"
          v-model="formType.data[item.prop]"
          :placeholder="item.placeholder || '请选择'"
          :style="item.style || 'width: 70%;'"
          :filterable="item.filterable"
          clearable
          :disabled="item.disabled || false"
        >
          <el-option
            v-for="(v, index) in item.list"
            :key="index"
            :label="v.name"
            :value="v.id"
          />
        </el-select>
        <el-cascader
          v-else-if="item.Ftype === 'cascader'"
          ref="cascaderAddr"
          placeholder="请选择"
          :options="item.options"
          :style="item.style || 'width: 70%;'"
          v-model="formType.data[item.prop]"
          filterable
          clearable
          :disabled="item.disabled || false"
          @change="cascaderChange"
        ></el-cascader>
        <el-date-picker
          v-else-if="item.Ftype === 'datePicker'"
          v-model="formType.data[item.prop]"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :style="allProps.style || 'width: 100%;'"
          @change="getDate(formType.data[item.prop], item.dateProps)"
          clearable
          :disabled="item.disabled || false" />
        <el-switch
          v-else-if="item.Ftype === 'switch'"
          v-model="formType.data[item.prop]"
          :disabled="item.disabled || false" />

        <el-checkbox-group
          v-else-if="item.Ftype === 'checkbox'"
          v-model="formType.data[item.prop]"
          :disabled="item.disabled || false"
          @change="$forceUpdate()"
          ><template v-if="item.list">
            <el-checkbox
              v-for="v in item.list"
              :key="v.value"
              :label="v.value"
              :disabled="v.disabled || false"
              >{{ v.label }}</el-checkbox
            >
          </template>
        </el-checkbox-group>
        <el-radio-group
          v-else-if="item.Ftype === 'radio'"
          v-model="formType.data[item.prop]"
          @change="$forceUpdate()"
          :disabled="item.disabled || false"
        >
          <el-radio v-for="v in item.list" :key="v.value" :label="v.value">{{
            v.label
          }}</el-radio>
        </el-radio-group>
        <el-image
          v-else-if="item.Ftype == 'image'"
          :style="item.style || 'width: 100px; height: 100px'"
          :src="formType.data[item.prop][0]"
          :preview-src-list="formType.data[item.prop]"
        >
        </el-image>
        <quill-editor
          v-else-if="item.Ftype == 'quillEditor'"
          v-model="formType.data[item.prop]"
          :options="item.options" />
        <template v-else-if="item.Ftype == 'slot'">
          <slot :name="item.prop" :data="formType.data" />
        </template>
        <template v-else-if="item.Ftype == 'upload'">
          <el-upload
            ref="upload"
            :limit="1"
            :action="$baseURL + sendUrl"
            list-type="picture-card"
            :on-success="
              (res, file) => handleSuccess(res, file, formType.refName)
            "
            :on-error="handleError"
            name="file"
            :on-change="
              (file, fileList) => handleChange(file, fileList, item.prop)
            "
            :file-list="formType.data[item.prop]"
            :auto-upload="false"
            :data="uploadData"
            ><!-- :auto-upload="false" -->
            <i class="el-icon-plus"></i>
          </el-upload>
        </template>

        <el-input
          v-else
          :style="item.style || 'width: 70%;'"
          autocomplete="new-password"
          v-model="formType.data[item.prop]"
          @input="$forceUpdate()"
          :type="item.Ftype || 'input'"
          :rows="item.rows || 2"
          clearable
          :show-password="item.Ftype === 'password'"
          :disabled="item.disabled || false" /></el-form-item
    ></template>
    <el-form-item
      ref="btnForm"
      class="btnForm"
      v-if="allProps.isShowBtn.dialogFormBtn === 'false' ? false : true"
    >
      <el-button type="primary" @click="submitForm(formType.refName,'search')">{{
        !formType.value ? "搜索" : "确定"
      }}</el-button>
      <el-button @click="resetForm(formType.refName)">{{
        !formType.value ? "重置" : "取消"
      }}</el-button>
      <el-button type="primary" @click="submitForm(formType.refName, 'excel')">
        导出为Excel
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
var moment = require("moment");
import { getTable } from "@/hooks";
import { quillEditor } from "vue-quill-editor";
import { getExcel } from "../../utils/index";

export default {
  name: "Hform",
  props: {
    allProps: {
      type: Object,
      default: () => ({}),
    },
    formType: {
      type: Object,
      default: () => ({
        type: "search",
        value: 0,
        data: { limit: [], dizhi: [] },
      }),
    },
  },
  inject: ["changeRuleForm", "getTableList"],

  data() {
    return {
      rules: {},
      userInfo: {},
      dialogImageUrl: "",
      dialogVisible: false,
      imageUrl: "",
      qiniuData: {},
      action: "",
      sendUrl: "",
      uploadData: {},
      initAddData: {},
    };
  },
  components: {
    quillEditor,
  },
  watch: {
    "formType.type": {
      handler(newVal, oldVal) {
        if (newVal === "add") {
          this.changeDataDefultArrary();
          // this.formType.data = { ...this.initAddData };
        }
        this.sendUrl = this.allProps.api[newVal];
      },
      deep: true,
      immediate: true,
    },
    "allProps.items": {
      handler(val) {
        this.getRules();
      },
    },
  },
  created() {
    this.userInfo = JSON.parse(localStorage.userInfo);
    this.uploadData.token = this.userInfo.token;
    this.uploadData.name = this.userInfo.name;
    this.formType.data = { ...this.allProps.data };
    this.changeDataDefultArrary();
    // value为0则搜索，不需要进行下面的rules赋值
    this.getRules();
    // rules为undefined或null也默认要rules验证，只有为false才不执行验证、
    let str = "babababababa";
    // let obj = {};
    let res = [...str].reduce((a, b) => {
      if (b === "a") a++;
      return a;
    }, 0);
    console.log(res);
  },
  methods: {
    cascaderChange(value) {
      let arr = this.$refs.cascaderAddr[0].getCheckedNodes()[0].pathLabels
        console.log(arr);
        return arr
      },
    getRules() {
      this.allProps.rules ??= true;
      if (!this.allProps.rules) return;
      this.rules = {};
      this.allProps.items.forEach((i) => {
        i.rule ??= true;
        if (!i.rule || !i.type.includes(this.formType.type)) {
          return;
        } else if (i.rule?.length) {
          this.rules[i.prop] = i.rule;
        } else if (!i.Ftype || i.Ftype == "textarea") {
          this.rules[i.prop] = [
            { required: true, message: "请填写", trigger: "blur" },
          ];
        } else {
          this.rules[i.prop] = [
            { required: true, message: "请选择", trigger: "change" },
          ];
        }
      });
    },
    handleSuccess(res, file, formName) {
      this.getTableList();
      this.resetForm(formName);
      console.log("上传成功", res, file, URL.createObjectURL(file.raw));
    },
    handleError(err, file, fileList) {
      console.log("上传失败", err, file, fileList);
    },
    handleChange(file, fileList, prop) {
      this.formType.data[prop] = fileList;
    },

    changeDataDefultArrary() {
      this.allProps.items.forEach((i) => {
        if (i.Ftype === "checkbox" && this.formType.type === "add") {
          // this.formType.data[i.prop] = [];
          this.formType.data[i.prop] = this.formType.data[i.prop]?.length
            ? this.formType.data[i.prop]
            : [];
        }
      });
    },
    getDate(date, props) {
      this.formType.data[props[0]] = moment(date[0]).format(props[2]);
      this.formType.data[props[1]] = moment(date[1]).format(props[2]);
    },
    submitForm(formName, type="") {
      console.log("type",type);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.formType.value) {
            if (type === "excel"){ this.formType.data.type = "excel";}else{
              this.formType.data.type = "search";
            }
            const resultData = await this.getTableList(this.formType.data);
            if (resultData&&type === "excel") {
              if(!resultData?.length){this.$message("暂无数据，无法导出");return}
              let selectArr=this.cascaderChange()
              await getExcel(
                resultData,
                this.allProps.isShowBtn.jsonFields,
                selectArr[0]+selectArr[1]+"财务列表"
              );
              /* await getExcel({data: resultData,
                header: this.allProps.isShowBtn.jsonFields,}); */
            }
          } else {
            let isUploadFlag = false;
            this.allProps.items.forEach((item) => {
              if (
                item.Ftype === "upload" &&
                item.type.includes(this.formType.type) &&
                this.formType.data[item.prop][0]?.raw
              )
                isUploadFlag = true;
            });
            if (!isUploadFlag) {
              this.formType.data.name = this.userInfo.name;
              this.formType.data.token = this.userInfo.token;
              let data = this.changeRuleForm?.(
                this.formType.data,
                this.uploadData
              );
              let that = this;

              this.$axios
                .post(this.sendUrl, data ? data : this.formType.data)
                .then(function (res) {
                  that.$message[res.data.code === "0" ? "success" : "info"](
                    res.data.message
                  );
                  if (res.data.code === "0") {
                    that.$message.success(res.data.message);
                    that.resetForm(formName);
                    that.getTableList();
                  }
                });
            } else {
              this.uploadData = this.changeRuleForm?.(
                this.formType.data,
                this.uploadData
              );
              console.log("formtype2", this.formType.data);
              this.$refs.upload[0].submit();
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName = "ruleForm") {
      this.$refs[formName].resetFields();
      this.allProps.dialog.isShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.formFlex {
  display: flex;
}
.btnForm {
  display: flex;
  .download-excel {
    display: inline-block;
    margin-left: 10px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
